"use client";
import { track } from "@amplitude/analytics-react-native";
import { usePathname, useRouter } from "next/navigation";
import { useEffect } from "react";
import { amp } from "src/api/amplitude";
import General404 from "src/screens/(error)/General404";

const shouldRedirect = (pathname: string) => {
	const path = `/${pathname.split("/").slice(1).join("/")}`;
	if (path.startsWith("/careers/")) {
		return "/careers";
	}
	if (path.startsWith("/p/")) {
		return "/";
	}
	return false;
};

const NotFoundPage = () => {
	const pathname = usePathname();
	const router = useRouter();

	useEffect(() => {
		track(amp.notFound.webNotFound, { path: pathname });
		const redirectTo = shouldRedirect(pathname);
		if (redirectTo) {
			// There's a weird bug where the screen splits and shows both pages??
			setTimeout(() => {
				router.push(redirectTo);
			}, 10);
		}
	}, [pathname, router]);

	if (shouldRedirect(pathname)) return <div></div>;
	return <General404 />;
};
export default NotFoundPage;
